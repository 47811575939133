import springStepper from '../animation/spring'
import { getScrollTop } from '../scrollUtils'

import Spring from './Spring'

export default class SpringPoint extends Spring {
  x = 0
  y = 0

  targetX = 0
  targetY = 0

  #velocityX = 0
  #velocityY = 0

  update(stepDeltaTimeS: number) {
    if (this.x !== this.targetX) {
      const [x, xVel] = springStepper(
        stepDeltaTimeS,
        this.x,
        this.#velocityX,
        this.targetX,
        this.stiffness,
        this.dampness,
        this.precision
      )
      this.x = x
      this.#velocityX = xVel
    }
    if (this.y !== this.targetY) {
      const [y, yVel] = springStepper(
        stepDeltaTimeS,
        this.y,
        this.#velocityY,
        this.targetY,
        this.stiffness,
        this.dampness,
        this.precision
      )
      this.y = y
      this.#velocityY = yVel
    }
  }

  updateTargetFromMouseEvent(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const boundingRect = event.currentTarget.getBoundingClientRect()

    const x = event.pageX - boundingRect.left
    const y = event.pageY - boundingRect.top - getScrollTop()

    this.targetX = x
    this.targetY = y
  }
}
