import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { InternalLink } from '../../components/_elements/InternalLink/InternalLink'
import LogoLink from '../../components/_elements/LogoLink'
import LayoutContainer from '../../components/_layouts/LayoutContainer'
import HeroHeading from '../../components/_typography/HeroHeading'
import Colors from '../../constants/Colors'
import useAnimationLoop from '../../hooks/useAnimationLoop'
import useElementBounds from '../../hooks/useElementBounds'
import useTimeout from '../../hooks/utils/useTimeout'
import Blob from '../../utils/blob-classes/Blob'
import SpringPoint from '../../utils/blob-classes/SpringPoint'

import styles from './404.module.scss'

interface FourOFourProps {}

export default function FourOFour({}: FourOFourProps) {
  const pathElRef = useRef<SVGPathElement>(null)
  const { elementRef: containerElRef, elementBoundsRef } =
    useElementBounds<HTMLDivElement>()

  const _setTimeout = useTimeout()
  const stopTimeoutIdRef = useRef(-1)

  const [blob] = useState(
    () =>
      new Blob({
        pointCount: 10,
        radius: 300,
        pointRadiusOffset: 0.2,
        pointSpeedOffset: 0.002,
      })
  )

  const [position] = useState(() => new SpringPoint(70, 20))

  const { start, stop } = useAnimationLoop((time) => {
    blob.update(time)
    position.update(time.elapsedS)

    const pathEl = pathElRef.current
    if (pathEl) {
      pathEl.setAttribute('d', blob.toSvgPathD())
      pathEl.setAttribute('transform', `translate(${position.x} ${position.y})`)
    }
  })

  function onMouseEnter(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    clearTimeout(stopTimeoutIdRef.current)

    start()
    blob.appear()

    position.targetX = event.pageX
    position.targetY = event.pageY
  }

  function onMouseLeave(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    stopTimeoutIdRef.current = _setTimeout(stop, 500)
  }

  function onMouseMove(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    position.targetX = event.pageX
    position.targetY = event.pageY
  }

  return (
    <div
      className={styles.container}
      ref={containerElRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      <Helmet>
        <title>Kruso - 404</title>

        <meta name="theme-color" content={Colors.GreenDark} />

        <html lang="en" />
      </Helmet>

      <LogoLink color={Colors.Yellow} />

      <svg
        className={styles.blob}
        viewBox={`0 0 ${elementBoundsRef.current.domRect?.width ?? 0} ${
          elementBoundsRef.current.domRect?.height ?? 0
        }`}
      >
        <defs>
          <clipPath id="blob-mask">
            <path ref={pathElRef} />
          </clipPath>
        </defs>
      </svg>

      <img
        className={styles.backgroundImage}
        style={{ clipPath: `url(#blob-mask)` }}
        src="/404-background.jpg"
      />

      <LayoutContainer className={styles.layoutContainer}>
        <HeroHeading>Page Not Found</HeroHeading>
        <p>Sorry, we can't find that page.</p>
        <p>
          Go to{' '}
          <InternalLink
            to="/"
            segmentdata={{
              anchor_text: 'Start Page',
              position: 'body',
              url: '/',
            }}
          >
            Start Page
          </InternalLink>
        </p>
      </LayoutContainer>
    </div>
  )
}
