export function getElementOffset(
  element: Element | undefined | null,
  offsets = { top: 0, left: 0 }
): { top: number; left: number } {
  if (!isHTMLElement(element)) {
    return offsets
  }

  offsets.top += element.offsetTop
  offsets.left += element.offsetLeft

  return getElementOffset(element.offsetParent, offsets)
}

export function isHTMLElement(
  element: Element | undefined | null
): element is HTMLElement {
  return !!element && 'offsetTop' in element
}
