import { useCallback, useEffect, useRef } from 'react'

import { getElementOffset } from '../utils/domUtils'

import useWindowSize from './useWindowSize'
import useForceRender from './utils/useForceRender'

interface ElementBounds {
  domRect: DOMRect | null
  offset: { top: number; left: number } | null
}

export default function useElementBounds<T extends HTMLElement>(
  triggerRerenderAfterFirstMeasure = true,
  alwaysCalculateBoundsAfterRender = true
) {
  useWindowSize()

  const forceRender = useForceRender()

  const elementRef = useRef<T>(null)

  const elementBoundsRef = useRef<ElementBounds>({
    domRect: null,
    offset: null,
  })

  useEffect(() => {
    if (alwaysCalculateBoundsAfterRender) {
      calculateElementBounds()
    }
  })

  const calculateElementBounds = useCallback(
    function calculateBounds() {
      const element = elementRef.current
      if (element) {
        const isFirstMeasure = elementBoundsRef.current.domRect === null

        elementBoundsRef.current.domRect = element.getBoundingClientRect()
        elementBoundsRef.current.offset = getElementOffset(element)

        if (isFirstMeasure && triggerRerenderAfterFirstMeasure) {
          forceRender()
        }
      }
    },
    [elementRef.current]
  )

  return {
    elementRef,
    elementBoundsRef,
    calculateElementBounds,
  }
}
